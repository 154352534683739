// import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import HomePage from '../views/home.vue';
import SingersPage from '../views/singers_page.vue';

const routes = [
  {
    path: '/',
    name: '导航',
    component: HomePage,
  },
  {
    path: '/singers',
    name: '歌手',
    component: SingersPage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = 'DiffSinger - ' + to.name; // 设置页面标题为路由的名称
  next();
});

export default router