<template>
	<n-layout>
		<n-layout-header>singers</n-layout-header>
	</n-layout>
</template>
<script>
/* eslint-disable */
import { defineComponent, ref, version } from 'vue'
import { darkTheme } from 'naive-ui'
import { useThemeVars } from 'naive-ui'
import { NConfigProvider, NButton, NSpace, NCard, NEl, NFlex, NLayout, NMenu, NInput, NIcon, NScrollbar, NEllipsis, NPagination, NTooltip, NDropdown, NCarousel } from 'naive-ui'
import { Search32Regular } from '@vicons/ionicons5'
import axios from "axios";
export default defineComponent({
	name: 'SingersPage',
	components: {
		NCarousel,
		NButton,
		NConfigProvider,
		NSpace,
		NCard,
		NEl,
		NLayout,
		NFlex,
		NMenu,
		NInput,
		NIcon,
		NScrollbar,
		NEllipsis,
		NPagination,
		NTooltip,
		NDropdown
	},
	data() {
		var singer_template = {
			name: "",
			id: "",
			avatar: "",
			introduction: "",
			demo_url: "",
			tags: "",
			sex: -1,
			age: 0,
			color: "",
			version: "",
			belong: "",
			down_url: "",
			drawby: "",
			height: "",
			weight: "",
			birth_day: "",
			mail: "",
			voice: "",
			class_id: 0
		}
		return {
			darkTheme,
			theme: ref(null),
			themeVars: useThemeVars(),
			singers: []
		}
	}
}
)
</script>