<template>
    <n-layout>
        <n-flex class="main_layout">
            <!-- 左侧区域 -->

            <n-flex vertical class="left_dock_container">
                <div class="left_dock">
                    <div class="logo_slot">
                        <img width="54px" src="//cloud.zhibin.club/dfs/dslogo.svg?866" alt="">
                    </div>
                    <n-scrollbar style="height: 100%;max-height: 100%; width: 96%;">
                        <n-el tag="div" v-for="item in options" v-bind:key="item.key" class="menu_item"
                            :class="{ active: item.key === selectedOptionKey }" @click="onMenuItemClick(item.key)">
                            <n-el tag="div" class="active_bar" :class="{ active: item.key === selectedOptionKey }">
                                <!-- 激活状态条 -->
                            </n-el>
                            <div
                                style="display: flex; align-items: center; justify-content: center; width: 72px;height: 48px;">
                                <img width="20px" v-bind:src="item.icon" alt="">
                            </div>
                            <span>{{ item.label }}</span>
                        </n-el>
                    </n-scrollbar>
                    <div class="QQGroup">
                        <n-card style="width: 220px;gap: 20px;border-radius: 10px;">
                            <div
                                style="width: 100%; display: flex;flex-direction: column;align-items: center;gap: 20px;">
                                <img width="100px" src="//cloud.zhibin.club/dfs/QQ.svg" alt="">
                                <span style="font-size: 16px;">DiffSinger QQ 交流群</span>
                                <span>群号: 907879266</span>
                                <a href="http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=By4ZsSG-9E00i-BfOnnD2VY2trNa6rH7&authKey=xeoKgfJsobQAnq6FCgcP%2BHy8lu4JNnjTNUqFO93f%2BS7cvBKVs2efz%2FQPSXq%2F%2BP5E&noverify=0&group_code=907879266"
                                    target="_blank">
                                    <n-button>加入群聊</n-button>
                                </a>
                            </div>
                        </n-card>
                    </div>
                </div>


            </n-flex>
            <!-- 内容区域 -->
            <div class="content">
                <n-flex class="content_layout" vertical style="gap:20px;max-height: 100%;">
                    <n-input v-model:value="keywords" placeholder="搜索" round size="large" :on-change="updateMenu()">
                        <template #prefix>
                            <img height="18px" width="40px" src="//cloud.zhibin.club/dfs/Search.svg" alt="">
                        </template>
                    </n-input>
                    <n-carousel loop autoplay direction="vertical" dot-placement="right" show-arrow class="banner"
                        v-if="banner">
                        <a :href="item.link" v-for="item in banner" :key="item.id" target="_blank">
                            <img style="margin: 0 auto;width: 100%;height: 100%;object-fit: cover;" :src="item.image">
                        </a>
                    </n-carousel>

                    <div class="content_title_layout">
                        <div class="content_title_layout">
                            <img height="20px" :src="options.find(item => item.key === selectedOptionKey).icon" alt="">
                            <span>{{ options.find(item => item.key === selectedOptionKey).label }}</span>
                        </div>
                        <n-dropdown placement="bottom-start" trigger="click" size="large" :options="dropo_options"
                            @select="onMenuItemClick">
                            <n-button round>
                                <img height="16px" src="//cloud.zhibin.club/dfs/Category.svg" alt=""
                                    style="opacity: 0.75;">
                            </n-button>
                        </n-dropdown>
                    </div>
                    <n-scrollbar class="content_item_layout">

                        <div class="content_items_layout">
                            <n-el tag="a" :href="item.link" target="_blank" class="content_item"
                                v-for="item in result_items" v-bind:key="item.id">
                                <n-tooltip :show-arrow="false" trigger="hover" width="300">
                                    <template #trigger>
                                        <div class="content_item_info">
                                            <img :src="item.img" alt=""
                                                style="width: 88px;height: 88px;object-fit: cover;border-radius: 6px;">
                                            <div class="content_item_info_text">
                                                <span
                                                    style="font-size: 16px;font-weight: 500;max-width: 100%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;height: 24px;">{{
                                                        item.name }}</span>
                                                <n-el tag="span"
                                                    style="max-width: 100%;max-height:58px;text-overflow: ellipsis;overflow: hidden;white-space: wrap;color: var(--text-color-3);line-clamp: 3;">{{
                                                        item.des }}
                                                </n-el>
                                            </div>
                                        </div>
                                    </template>
                                    {{ item.name }}<br>
                                    {{ item.des }}
                                </n-tooltip>


                            </n-el>



                        </div>
                    </n-scrollbar>
                    <div class="pagination_layout">
                        <n-pagination v-model:page="page" :page-count="total_pages" class="pagination" simple />
                    </div>
                </n-flex>
            </div>
            <!-- 右侧区域 -->
            <n-flex vertical class="right_dock_container">
                <div class="right_dock">
                    <div>
                        <a href="https://github.com/openvpi/DiffSinger" target="_blank" rel="noopener noreferrer">
                            <img src="//cloud.zhibin.club/dfs/openvpi-github.svg" alt="" style="width: 100%;">
                        </a>
                        <div style="display: flex;
                                    flex-direction: column;
                                    padding: 0px;
                                    gap: 20px;
                                    align-self: stretch;
                                    margin-top: 20px;
                                    ">
                            <div style="display: flex;flex-direction: column;gap: 5px;">
                                <span style="font-size: 16px; font-weight: 600;">
                                    快速入门
                                </span>
                                <n-el tag="span" style="color: var(--text-color-3);">
                                    以下链接可以帮助您更快速的入门DiffSinger
                                </n-el>
                            </div>
                            <div style="display: flex;
                                    flex-direction: column;
                                    padding: 0px;
                                    gap: 10px;
                                    ">
                                <div v-for="item in quickstart" v-bind:key="item.key">

                                    <n-el tag='a' :href="item.link" target="_blank" rel="noopener noreferrer">
                                        <n-card style="width: 280px;gap: 20px;border-radius: 10px;">
                                            <div
                                                style="display: flex;gap: 10px;align-items: center;justify-content: space-between;">
                                                <div style="display: flex;gap: 10px;align-items: center;">
                                                    <img :src="item.img" alt=""
                                                        style="border-radius: 6px;width:48px;height: 48px;object-fit: cover;">
                                                    <div style="display: flex;flex-direction: column;gap: 5px;">
                                                        <span>{{ item.label }}</span>
                                                        <span style="color: var(--text-color-3);font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;max-width: 100%;">{{
                                                            item.description
                                                            }}</span>
                                                    </div>
                                                </div>
                                                <!-- <img :src="item.icon" alt=""> -->
                                            </div>
                                        </n-card>

                                    </n-el>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <n-el tag="span" style="width: 100%;color: var(--text-color-3);">
                            <p @click="show_visitors()">DiffSinger歌声合成导航</p>
                            <p v-if="total_visitors_show > 6" style="color: var(--text-color-3);">累计访客: {{ total_visitors.ip_count }}</p>

                            <a style="color: var(--text-color-3);" href="https://beian.miit.gov.cn/" target="_blank"
                                rel="noopener noreferrer">闽ICP备2021000177号-3</a> <br>
                            <br>
                            {{ `© Copyright ${new Date().getFullYear()} Team OpenVPI.` }}<br>
                            <br>
                            All Rights Reserved<br>

                        </n-el>
                    </div>
                </div>


            </n-flex>
        </n-flex>
    </n-layout>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from 'vue'
import { darkTheme } from 'naive-ui'
import { useThemeVars } from 'naive-ui'
import { NConfigProvider, NButton, NSpace, NCard, NEl, NFlex, NLayout, NMenu, NInput, NIcon, NScrollbar, NEllipsis, NPagination, NTooltip, NDropdown, NCarousel } from 'naive-ui'
import { Search32Regular } from '@vicons/ionicons5'
import axios from "axios";
export default defineComponent({
    name: 'HomePage',
    components: {
        NCarousel,
        NButton,
        NConfigProvider,
        NSpace,
        NCard,
        NEl,
        NLayout,
        NFlex,
        NMenu,
        NInput,
        NIcon,
        NScrollbar,
        NEllipsis,
        NPagination,
        NTooltip,
        NDropdown
    },
    data() {
        const quickstart = [
            {
                img: "//cloud.zhibin.club/dfs/fs.svg",
                icon: "//cloud.zhibin.club/dfs/goto.svg",
                label: "DiffSinger 教程",
                description: "学习制作DiffSinger声库",
                key: 1,
                link: "https://ecn4x4y9jhjs.feishu.cn/wiki/UK6xwL37NivMfDk8PnnckZGUngZ?from=from_copylink"
            },
            {
                img: "//cloud.zhibin.club/dfs/openutau.svg",
                icon: "//cloud.zhibin.club/dfs/goto.svg",
                label: "OpenUTAU 编辑器",
                description: "上手安装并使用DiffSinger声库",
                key: 2,
                link: "https://github.com/stakira/OpenUtau/releases"
            },
            {
                img: "https://cloud.zhibin.club/uploads/images/20240419/20240419040046a65a02544.png",
                icon: "//cloud.zhibin.club/dfs/goto.svg",
                label: "DiffSinger 声库获取",
                description: "海量声库 任你挑选",
                key: 3,
                link: "https://docs.qq.com/sheet/DQXNDY0pPaEpOc3JN?tab=BB08J2"
            }
        ]
        const options = [
            {
                icon: "//cloud.zhibin.club/dfs/Category.svg",
                label: "全部项目",
                key: 999
            }
        ];
        const dropo_options = [
            {
                label: "全部项目",
                key: 999
            }
        ];

        const result_items = [

        ]
        console.log(111, darkTheme);
        return {
            Search32Regular,
            darkTheme,
            theme: ref(null),
            themeVars: useThemeVars(),
            dropo_options,
            options,
            selectedOptionKey: 999, // 新增：当前选中的菜单项键
            result_items,
            selectedOptionId: 0, // 新增：当前选中的菜单项ID
            page: 1,
            keywords: '',
            total_pages: 1,
            quickstart,
            limit: 20,
            banner: null,
            total_visitors: { ip_count: 0, times_count: 0 },
            total_visitors_show: 0,
        }
    },
    methods: {
        show_visitors() {
            this.total_visitors_show ++
        },
        onMenuItemClick(key) {
            this.selectedOptionKey = key; // 更新选中菜单项的键
            this.page = 1
            this.updateMenu()
        },
        updateMenu() {
            //获取菜单
            if (this.page > this.total_pages) {
                this.page = 1
            }
            axios.get(`//api.diffsinger.com/api/index/get_nav?page=${this.page}&limit=${this.limit}&keywords=${this.keywords}&class_id=${this.selectedOptionKey}`).then(res => {
                console.log(res.data.data);
                this.total_pages = res.data.data.total_pages;
                this.result_items = res.data.data.data;
            })
        },
    },
    mounted() {
        // 获取统计
        axios.get("//api.diffsinger.com/api/index/get_total_visitors").then(res => {
            console.log(res.data.data);
            this.total_visitors = res.data.data;
        })
        //获取banner
        axios.get("//api.diffsinger.com/api/index/get_banner").then(res => {
            console.log(res.data.data);
            this.banner = res.data.data;
        })
        //获取分类
        axios.get("//api.diffsinger.com/api/index/get_nav_class").then(res => {
            console.log(res.data.data);
            res.data.data.forEach(element => {
                this.options.push({
                    icon: element.icon,
                    label: element.class_name,
                    key: element.class_id
                })
                this.dropo_options.push({
                    label: element.class_name,
                    key: element.class_id
                })
            });
        })
        this.updateMenu()
    }
})
</script>

<style>
.main_layout {
    width: 100vw;
    height: 100vh;
    padding: 0;
    gap: 0 !important;
    background: #00000008;
    flex-wrap: nowrap !important;
}

a {
    text-decoration: none;
    color: var(--text-color-2);
}

.content_item_info {
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 20px;
    flex-grow: 1;
    width: 100%;
    max-height: 100%;
    flex-wrap: nowrap !important;
    overflow: hidden;
}

.content_item_info_text {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    /* gap: 10px; */
    justify-content: space-between;

}

@media screen and (max-width: 1700px) {
    .content_item_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
    }

    .content_item_info_text {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        width: 100%;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }

    .content_item {
        width: calc((100% - 220px) / 4);
        border-radius: 10px;
        opacity: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px;
        gap: 20px;
        background: rgba(153, 156, 173, 0.1);
        justify-content: center;
    }
}

.left_dock_container {}

.right_dock_container {}

.pagination_layout {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
}

@media screen and (max-width: 550px) {
    .content_item {
        width: calc(50% - 50px) !important;

    }
}

@media screen and (max-width: 1024px) {
    .left_dock_container {
        display: none !important;
    }

    .right_dock_container {
        display: none !important;
    }

    .content {
        width: 100vw !important;
        overflow: hidden;
    }

    .pagination_layout {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
    }

    .pagination {
        width: 100%;
    }

    .content_item {
        min-width: 117px;
    }

    /* .banner {
        display: none;
    } */

}

.content_items_layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 20px;
    align-self: stretch;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    /* margin-top: 20px; */
}

.content_item {
    /* 默认1080 */
    width: calc((100% - 220px) / 4);
    /* max-width: 360px; */
    /* height: 128px; */
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    gap: 20px;
    background: rgba(153, 156, 173, 0.1);
    cursor: pointer;
    transition: 0.48s;

}

.content_item:hover {
    width: calc((100% - 220px) / 4);
    background: rgba(15, 163, 82, 0.1);
}


.logo_slot {
    display: flex;
    width: 100%;
    height: 180px;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    padding: 20px;
    width: calc(100% - 620px);
}

.content_layout {
    width: 100%;
}

.content_title_layout {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    padding: 0px;
    align-self: stretch;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
}

.content_item_layout {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 20px;
    align-self: stretch;
}

.left_dock {
    display: flex;
    width: 260px;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-width: 0px 0.5px 0px 0px;
    border-style: solid;
    border-color: rgba(153, 156, 173, 0.2);
}

.right_dock {
    display: flex;
    width: 280px;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-width: 0px 0px 0px 0.5px;
    border-style: solid;
    border-color: rgba(153, 156, 173, 0.2);
    padding: 20px;
}

.menu_item {
    display: flex;
    border-radius: 0px 10px 10px 0px;
    width: 100%;
    height: 72px;
    flex-direction: row;
    background-color: #ffffff00;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color-3);
    cursor: pointer;
}

.menu_item.active {
    color: var(--text-color-2);
    font-weight: 500;
    background-color: var(--base-color);
    transition: 0.48s;
}

.active_bar {
    width: 8px;
    height: 0px;
    border-radius: 233px;
    opacity: 0;
    align-self: stretch;
    background: var(--primary-color);
    box-shadow: 0px 4px 14px 0px var(--primary-color) 20%;
}

.active_bar.active {
    height: 72px;
    opacity: 1;
    transition: 0.48s;
}

.QQGroup {
    width: 100%;
    padding: 20px 20px 20px 20px;
}

.banner {
    border-radius: 10px;
    height: 215px;
    object-fit: cover;
    /* display: none; */
}
</style>