<template>
  <div id="app">
    <!-- 其他应用程序内容 -->
    <n-config-provider :theme="theme">
      <!-- <n-theme-editor> -->

      <n-space style="position: fixed;top: 0;left: 0; z-index: 999;background: var(--primary-color);">
        <!-- <n-button @click="changetheme()">{{ theme === null ? '关灯' : '开灯' }}</n-button> -->
      </n-space>
      <router-view></router-view> <!-- 路由视图占位符 -->
      <!-- </n-theme-editor> -->
      <n-float-button @click="changetheme()" style="position: fixed;bottom: 20px;right: 20px;">
          <img src="//cloud.zhibin.club/dfs/ld.svg" alt="">
      </n-float-button>
    </n-config-provider>
  </div>
</template>

<script>
/* eslint-disable */

import { defineComponent,computed, ref, watch } from 'vue'
import { darkTheme } from 'naive-ui'
import {useOsTheme, useThemeVars } from 'naive-ui'
import { NConfigProvider, NSpace, NFloatButton } from 'naive-ui'
export default defineComponent({
  name: 'App',
  // 其他组件选项
  components: {
    NConfigProvider,
    NSpace,
    NFloatButton,
    // NThemeEditor
  },
  setup() {
    const osThemeRef = useOsTheme();
    const theme = ref(osThemeRef.value === "dark" ? darkTheme : null)
    watch(osThemeRef, (newValue) => {
      if (newValue === "dark") {
        theme.value = darkTheme;
      } else {
        theme.value = null;
      }
    });
    return {
      theme,
      osTheme: osThemeRef
    };
  },
  methods: {
    changetheme() {
      this.theme = this.theme === null ? darkTheme : null
    }
  }
})
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
page{
  margin: 0;
  padding: 0;
}
/* 其他样式 */
</style>